import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { VueTelInput } from "vue-tel-input";

import { ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PhoneInput',
  props: {
  name: {
    type: String,
    default: 'phone',
  },
},
  emits: {
  'update:value': (value: string) => typeof value === 'string',
  'validation': (value?: boolean) => typeof value === 'boolean' || value === undefined
},
  setup(__props, { emit: __emit }) {

// @ts-ignore


const value = ref('');
const phone_input = ref();

const emit = __emit;

const emitValue = () => {
  emit('update:value', value.value);
};


const onValidatePhone = (phoneObject : any) => {
  emit('validation', phoneObject.valid);
}

watch(()=> value.value, emitValue);

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, { name: __props.name }, {
    default: _withCtx(() => [
      _createVNode(_unref(VueTelInput), _mergeProps({
        class: "myPhoneInput",
        ref_key: "phone_input",
        ref: phone_input,
        modelValue: value.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        dropdownOptions: { showFlags: true, showDialCodeInList: true, width: '100%', disabled: true },
        inputOptions: { showDialCode: true, maxlength: 13 },
        defaultCountry: 'GB',
        onlyCountries: ['GB'],
        validCharactersOnly: false,
        mode: 'international',
        onValidate: onValidatePhone
      }, _ctx.$attrs), null, 16, ["modelValue"])
    ]),
    _: 1
  }, 8, ["name"]))
}
}

})