import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Spin as ASpin } from 'ant-design-vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Spin',
  props: ['size'],
  setup(__props) {

const props = __props;

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: props.size ?? '24px',
  },
  spin: true,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ASpin), {
    indicator: _unref(indicator),
    class: "spinner"
  }, null, 8, ["indicator"]))
}
}

})