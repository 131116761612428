import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxInput',
  props: {
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
},
  emits: {
  'update:checked': (value: boolean) => typeof value === 'boolean',
},
  setup(__props, { emit: __emit }) {

const props = __props;

const value = ref();

const emit = __emit;

const emitChecked = () => {
  emit('update:checked', value.value);
};

watch(()=>value.value, emitChecked);

return (_ctx: any,_cache: any) => {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, { name: __props.name }, {
    default: _withCtx(() => [
      _createVNode(_component_a_checkbox, _mergeProps({
        checked: value.value,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        style: {"user-select":"none"}
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.label), 1)
        ]),
        _: 1
      }, 16, ["checked"])
    ]),
    _: 1
  }, 8, ["name"]))
}
}

})