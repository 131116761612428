import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from "vue";
import VOtpInput from "vue3-otp-input";


export default /*@__PURE__*/_defineComponent({
  __name: 'CodeInput',
  props: {
  num_inputs: {
    type: Number,
    default: 6,
  },
  name: {
    type: String,
    default: 'code',
  },
  parent_value: {
    type: String,
    default: '',
  },
},
  emits: {
  'update:value': (value: string) => typeof value === 'string',
  'complete': (value: string) => typeof value === 'string',
},
  setup(__props, { emit: __emit }) {

const otpInput = ref<InstanceType<typeof VOtpInput>>();


const props = __props;

const value = ref('');

const emit = __emit;

function handleOnPaste(e: any): void{
  if(e?.clipboardData?.getData){
    e.preventDefault()
    value.value = e.clipboardData.getData('text/plain')
  }
}

watch(()=> value.value, (async (): Promise<void> => {
  /** this function workarounds double-sending on pasting when input already filled */
  const val = value.value;
  emit('update:value', value.value);
  setTimeout(() => {
    if(value.value.length === props.num_inputs && val === value.value){
      emit('complete', value.value);
    }
  }, 1)
  
  
}));
watch(()=> props.parent_value, ()=> value.value = props.parent_value);

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, { name: __props.name }, {
    default: _withCtx(() => [
      _createVNode(_unref(VOtpInput), _mergeProps({
        ref_key: "otpInput",
        ref: otpInput,
        value: value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        "input-classes": "otp-input",
        class: "otp-input-wrapper",
        separator: "",
        "num-inputs": __props.num_inputs,
        "should-auto-focus": true,
        "should-focus-order": true,
        onPaste: _cache[1] || (_cache[1] = (e)=>handleOnPaste(e)),
        "input-type": "number"
      }, _ctx.$attrs), null, 16, ["value", "num-inputs"]),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["name"]))
}
}

})