import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordInput',
  props: {
placeholder: {
  type: String,
  default: 'Password',
},
name: {
  type: String,
  default: 'password',
},
},
  emits: {
'update:value': (value: string) => typeof value === 'string'
},
  setup(__props, { emit: __emit }) {



const value = ref('');

const emit = __emit;

const emitValue = () => {
emit('update:value', value.value);
};

watch(()=> value.value, emitValue);


return (_ctx: any,_cache: any) => {
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, { name: __props.name }, {
    default: _withCtx(() => [
      _createVNode(_component_a_input_password, _mergeProps({
        value: value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        placeholder: __props.placeholder
      }, _ctx.$attrs), {
        prefix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "prefix")
        ]),
        _: 3
      }, 16, ["value", "placeholder"])
    ]),
    _: 3
  }, 8, ["name"]))
}
}

})