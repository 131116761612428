import { http } from "@/shared/api";

const llm_url = '/ms/llm/api/v1/';

export const fetchThreads = async () => {
  const response = await http.get(llm_url + 'threads');
  return response.data.data;
}
export const fetchMessages = async () => {
  const response = await http.get(llm_url + 'threads/msg');
  return response.data.data;
}
export const createMessage = async (message : string) => {
  const response = await http.post(llm_url + 'threads/messages/create', {
    message
  });
  return response.data;
}
export const createThread = async () => {
  const response = await http.post(llm_url + 'threads/create');
  return response.data;
}
export const toggleThread = async (active : boolean) => {
  if(active){
    await http.post(llm_url + 'threads/active');
    await http.post(llm_url + 'threads/toggle/');
  } else {
    await http.post(llm_url + 'threads/inactive');
  }
}