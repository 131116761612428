import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "defaultTabs" }

import { ref, watch } from 'vue';
import { Tabs as ATabs, TabPane as ATabPane } from 'ant-design-vue';

type Tabs = {
  name: string,
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Tabs',
  props: {
  active_tab: {
    type: Number,
    default: 0
  },
  tabs: {
    type: Array as () => Tabs[],
    default: () => []
  },
},
  emits: ['change'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const props = __props
const activeKey = ref(props.active_tab)

watch(() => props.active_tab, (value) => {
  activeKey.value = value
})  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ATabs), {
      activeKey: activeKey.value,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((activeKey).value = $event)),
      type: "card",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (emit('change', $event)))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tabs, (tab, index) => {
          return (_openBlock(), _createBlock(_unref(ATabPane), {
            key: index,
            tab: tab.name,
            "force-render": ""
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, 'tab_' + (index))
            ]),
            _: 2
          }, 1032, ["tab"]))
        }), 128))
      ]),
      _: 3
    }, 8, ["activeKey"])
  ]))
}
}

})