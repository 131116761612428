import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "21",
  viewBox: "0 0 25 21",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill", "stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconBell',
  props: {
    color: { default: 'var(--color-primary)' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M22.581 16.4079H12.9235V14.6475H22.1226C22.379 14.6475 22.5896 14.4461 22.6014 14.1901C22.6085 14.0344 22.612 13.8774 22.612 13.7198C22.612 8.27369 18.308 3.81498 12.9232 3.56372V1.959H14.8917C15.1564 1.959 15.3712 1.7445 15.3712 1.4795C15.3712 1.2145 15.1564 1 14.8917 1H9.99605C9.73104 1 9.51655 1.2145 9.51655 1.4795C9.51655 1.7445 9.73104 1.959 9.99605 1.959H11.9646V3.56372C7.46493 3.77374 3.72132 6.92118 2.61144 11.1277C2.60824 11.1395 2.60408 11.1504 2.60153 11.1625C2.38895 11.9802 2.27515 12.8369 2.27515 13.7198C2.27515 13.8778 2.27898 14.0344 2.28602 14.1901C2.29784 14.4461 2.50882 14.6475 2.7652 14.6475H11.9646V16.4079H2.30712C1.58627 16.4079 1 16.9942 1 17.7147V19.2318C1 19.9524 1.58627 20.5386 2.30712 20.5386H22.581C23.3018 20.5386 23.8884 19.9524 23.8884 19.2318V17.7147C23.8884 16.9942 23.3018 16.4079 22.581 16.4079ZM3.45216 11.7344L3.72004 10.7754C4.95171 7.13727 8.3958 4.51026 12.4441 4.51026C17.5114 4.51026 21.6357 8.62468 21.653 13.6882H3.23447C3.23702 13.0179 3.45216 11.7344 3.45216 11.7344ZM22.9294 19.2318C22.9294 19.4236 22.7731 19.5796 22.581 19.5796H2.30712C2.115 19.5796 1.959 19.4236 1.959 19.2318V17.7147C1.959 17.5229 2.11532 17.3669 2.30712 17.3669H22.581C22.7731 17.3669 22.9294 17.5229 22.9294 17.7147V19.2318Z",
      fill: _ctx.color,
      stroke: _ctx.color,
      "stroke-width": "0.3"
    }, null, 8, _hoisted_2)
  ]))
}
}

})