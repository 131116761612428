import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "27",
  viewBox: "0 0 20 27",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconDoctor',
  props: {
    color: { default: 'var(--color-primary)' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M17.1636 17.5285C15.8956 17.0576 14.5707 16.716 13.2353 16.5038C12.9403 16.4572 12.6557 16.5917 12.5004 16.8402L10.1145 20.7012L7.72335 16.8454C7.56808 16.5969 7.28343 16.4624 6.98842 16.5089C5.65312 16.716 4.33334 17.0627 3.06015 17.5337C1.36255 18.16 0.21875 19.7851 0.21875 21.5758V26.2701C0.21875 26.6738 0.544812 26.9999 0.948508 26.9999H19.2701C19.6738 26.9999 19.9998 26.6738 19.9998 26.2701V21.581C20.005 19.7851 18.8664 18.16 17.1636 17.5285ZM18.5455 25.5352H1.68344V24.0964V22.6368L1.71449 21.1773C1.71449 21.1773 1.83289 20.4734 2.3925 19.7178C2.70303 19.3607 3.10155 19.076 3.56735 18.9052C4.5973 18.5222 5.66347 18.2324 6.73999 18.0357L9.4934 22.4764C9.78323 22.9681 10.4457 22.9319 10.7355 22.4764L13.4838 18.0357C14.5603 18.2324 15.6265 18.5222 16.6564 18.9052C17.7847 19.3245 18.5455 20.3958 18.5455 21.581V25.5352Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M16.6563 0.703125H3.72248C3.27738 0.703125 2.90991 1.12235 3.00825 1.58815L4.54022 8.74599C4.58163 8.94266 4.70584 9.10828 4.86628 9.21179C4.75242 9.65171 4.68514 10.1072 4.68514 10.5781C4.68514 13.5696 7.12284 16.0073 10.1143 16.0073C13.1058 16.0073 15.5435 13.5696 15.5435 10.5781C15.5435 10.133 15.4866 9.70347 15.3831 9.27907C15.6108 9.19626 15.7919 8.99959 15.8437 8.74599L17.3757 1.58815C17.474 1.15858 17.1272 0.703125 16.6563 0.703125ZM10.1143 14.5426C7.92505 14.5426 6.14465 12.7622 6.14465 10.573C6.14465 10.1382 6.21194 9.719 6.3465 9.32048H13.8822C14.0167 9.719 14.084 10.1382 14.084 10.573C14.0788 12.7622 12.2984 14.5426 10.1143 14.5426ZM14.5343 7.86096H14.379C14.3738 7.86096 5.84964 7.86096 5.84964 7.86096C5.84447 7.86096 4.61785 2.16782 4.61785 2.16782H15.7454L14.5343 7.86096Z",
      fill: _ctx.color
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M9.04811 5.74395H9.46216V6.15799C9.46216 6.56169 9.78822 6.88775 10.1919 6.88775C10.5956 6.88775 10.9217 6.56169 10.9217 6.15799V5.74395H11.3357C11.7394 5.74395 12.0655 5.41788 12.0655 5.01419C12.0655 4.61049 11.7394 4.28443 11.3357 4.28443H10.9217V3.87038C10.9217 3.46669 10.5956 3.14062 10.1919 3.14062C9.78822 3.14062 9.46216 3.46669 9.46216 3.87038V4.28443H9.04811C8.64442 4.28443 8.31836 4.61049 8.31836 5.01419C8.31836 5.41788 8.64442 5.74395 9.04811 5.74395Z",
      fill: _ctx.color
    }, null, 8, _hoisted_4)
  ]))
}
}

})