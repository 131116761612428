import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "27",
  height: "25",
  viewBox: "0 0 27 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill", "stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconHome',
  props: {
    color: { default: 'var(--color-primary)' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M13.8284 1.00016C13.6936 1.00339 13.5679 1.05336 13.447 1.14615L1.24362 11.9273C0.962849 12.1796 0.923015 12.6453 1.13323 12.9493C1.34042 13.2489 1.78112 13.2969 2.04647 13.0616L13.8484 2.63979L25.6504 13.0616C25.9157 13.2969 26.3443 13.2471 26.5536 12.9493C26.7613 12.6537 26.739 12.1627 26.4532 11.9273L14.2499 1.14615C14.1069 1.04077 13.9631 0.997013 13.8284 1.00016ZM5.81989 12.9493C5.81989 12.9493 5.81713 12.1405 5.17484 12.1405C4.53256 12.1405 4.53532 12.9493 4.53532 12.9493V23.2813C4.53534 23.4719 4.60301 23.6547 4.72346 23.7895C4.84391 23.9243 5.00727 24 5.17761 24H10.9582C11.1285 24 11.2919 23.9243 11.4123 23.7895C11.5327 23.6547 11.6004 23.4719 11.6004 23.2813V16.8126H16.0964V23.2813C16.0964 23.4719 16.1641 23.6547 16.2846 23.7895C16.405 23.9243 16.5684 24 16.7387 24H22.5193C22.6896 24 22.853 23.9243 22.9734 23.7895C23.0939 23.6547 23.1615 23.4719 23.1615 23.2813V12.9493C23.1615 12.9493 23.1615 12.1405 22.5165 12.1405C21.8714 12.1405 21.877 12.9493 21.877 12.9493V22.5625H17.381V16.0938C17.381 15.9032 17.3133 15.7204 17.1929 15.5856C17.0724 15.4508 16.909 15.3751 16.7387 15.3751H10.9582C10.7878 15.3751 10.6245 15.4508 10.504 15.5856C10.3836 15.7204 10.3159 15.9032 10.3159 16.0938V22.5625H5.81989V12.9493Z",
      fill: _ctx.color,
      stroke: _ctx.color,
      "stroke-width": "0.2"
    }, null, 8, _hoisted_2)
  ]))
}
}

})