import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
  disabled: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: 'Click Me',
  },
  html_type: {
    type: String,
    default: 'button',
  },
  type: {
    type: String,
    default: 'primary',
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, _mergeProps({
    type: __props.type,
    disabled: __props.disabled,
    "html-type": __props.html_type
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(__props.text), 1)
      ])
    ]),
    _: 3
  }, 16, ["type", "disabled", "html-type"]))
}
}

})