import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "uploadWrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "fileListWrapper"
}
const _hoisted_4 = { class: "name" }

import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import { FormItem as AFormItem, Upload as AUpload, Button as AButton, type UploadProps } from 'ant-design-vue';
import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputSingleFile',
  props: /*@__PURE__*/_mergeModels({
  name: {
    type: String,
    default: 'image',
  },
  placeholder: {
    type: String,
    default: 'Upload',
  },
  type: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['imageValidation', 'update:file'], ["update:modelValue"]),
  setup(__props, { emit: __emit }) {

const props = __props;
const value = _useModel<File | null>(__props, "modelValue");
const fileList = ref<UploadProps['fileList']>([])
const emit = __emit;
async function handleBeforeUpload(file : any) {
  if(file.size > 52428800) {
    emit('imageValidation', { [props.name] : 'Image size should be less than 50MB'})
    removeHandler()
    return false;
  }
  fileList.value = [ file ]
  value.value = file
  return false
}
function removeHandler() {
  value.value = null;
  fileList.value = []
}
watch(() => value.value, (newValue) => {
  emit('update:file', newValue);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(AFormItem), { name: __props.name }, {
      default: _withCtx(() => [
        _createVNode(_unref(AUpload), _mergeProps(_ctx.$attrs, {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
          showUploadList: false,
          beforeUpload: handleBeforeUpload,
          multiple: false,
          fileList: fileList.value,
          onRemove: removeHandler,
          class: fileList.value?.length ? 'uploaded' : 'notUploaded'
        }), {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(AButton), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(PlusCircleOutlined)),
                  _createTextVNode(_toDisplayString(__props.placeholder), 1)
                ]),
                _: 1
              }),
              (__props.text)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.text), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 16, ["modelValue", "fileList", "class"]),
        (value.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(value.value?.name), 1),
              _createVNode(_unref(DeleteOutlined), { onClick: removeHandler })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"])
  ]))
}
}

})