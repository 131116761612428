import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Textarea as ATextarea, FormItem as AFormItem } from 'ant-design-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'InputTextarea',
  props: /*@__PURE__*/_mergeModels({
  placeholder: {
    type: String,
    default: 'Enter',
  },
  name: {
    type: String,
    default: 'Text',
  },
}, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {


const value = _useModel<string>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AFormItem), { name: __props.name }, {
    default: _withCtx(() => [
      _createVNode(_unref(ATextarea), _mergeProps({
        value: value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        placeholder: __props.placeholder
      }, _ctx.$attrs), null, 16, ["value", "placeholder"])
    ]),
    _: 1
  }, 8, ["name"]))
}
}

})