import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Drawer as ADrawer } from 'ant-design-vue';
import { Button as AButton } from 'ant-design-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SaveDrawer',
  props: ['open', 'width', 'title', 'loading', 'onSave'],
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const emit = __emit

function onClose(){
  emit('close')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ADrawer), _mergeProps({
    title: __props.title,
    width: __props.width ?? 720,
    open: __props.open,
    "footer-style": { textAlign: 'right' },
    onClose: onClose
  }, _ctx.$attrs), {
    extra: _withCtx(() => [
      _createVNode(_unref(AButton), {
        style: {"margin-right":"8px"},
        onClick: onClose
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Cancel")
        ])),
        _: 1
      }),
      _createVNode(_unref(AButton), {
        type: "primary",
        onClick: __props.onSave,
        disabled: __props.loading,
        loading: __props.loading
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Save")
        ])),
        _: 1
      }, 8, ["onClick", "disabled", "loading"])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["title", "width", "open"]))
}
}

})