import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "22",
  height: "21",
  viewBox: "0 0 22 21",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = { id: "exit" }
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["stroke"]


export default /*@__PURE__*/_defineComponent({
  __name: 'IconExit',
  props: {
    color: { default: 'var(--color-primary)' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "Path",
        d: "M1 2.07108V2.07108V18.1843C1 18.7773 1.47994 19.2554 2.07681 19.2554H11.7355C12.3328 19.2554 12.8123 18.7766 12.8123 18.1843V2.07108C12.8123 1.4781 12.3324 1 11.7355 1H2.07681C1.47953 1 1 1.47876 1 2.07108V2.07108Z",
        stroke: _ctx.color,
        "stroke-width": "1.6"
      }, null, 8, _hoisted_3),
      _createElementVNode("rect", {
        id: "Rectangle 573",
        x: "7.44336",
        y: "9.3833",
        width: "12.8861",
        height: "1.45503",
        rx: "0.727515",
        fill: _ctx.color
      }, null, 8, _hoisted_4),
      _createElementVNode("path", {
        id: "Path 627",
        d: "M17.1074 6.36914L20.8659 10.1276L17.1074 13.8861",
        stroke: _ctx.color,
        "stroke-width": "1.6",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 8, _hoisted_5)
    ])
  ]))
}
}

})