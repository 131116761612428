import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "iconWrapper" }
const _hoisted_2 = { class: "text" }

import { IconHome, IconBell, IconDoctor, IconExit } from '@/shared/ui';
import { Button as AButton } from 'ant-design-vue';
import type { ButtonType } from 'ant-design-vue/es/button';


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonWithIcon',
  props: {
  text: {
    type: String,
    default: 'Default Text',
  },
  icon: {
    type: String,
    default: ''
  },
  active: {
    type: Boolean,
    default: false
  },
  type: {
    type: String as () => ButtonType,
    default: 'link'
  }
},
  setup(__props) {


const style = getComputedStyle(document.body)

const components: { [key: string]: any } = {
  IconHome,
  IconBell,
  IconDoctor,
  IconExit,
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AButton), _mergeProps({
    type: __props.type,
    text: "Button",
    class: "buttonWithIcon"
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.icon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(components[__props.icon]), {
              key: 0,
              color: __props.active || __props.type === 'primary' ? _unref(style).getPropertyValue('--color-white') : _unref(style).getPropertyValue('--color-primary')
            }, null, 8, ["color"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createElementVNode("span", _hoisted_2, _toDisplayString(__props.text), 1)
    ]),
    _: 3
  }, 16, ["type"]))
}
}

})