import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }


export default /*@__PURE__*/_defineComponent({
  __name: 'ComponentsProvider',
  props: ['model', 'fields'],
  emits: ['phoneValidation', 'imageValidation'],
  setup(__props, { emit: __emit }) {


const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.fields, (field) => {
    return (_openBlock(), _createElementBlock("div", {
      style: _normalizeStyle(field.preview ? 'margin-bottom: var(--spacing-lg)':''),
      class: "component",
      id: 'anchor_'+field.name
    }, [
      (field.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
            _createTextVNode(_toDisplayString(field.label), 1),
            (field.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (required)"))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (field.type==='component')
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), { key: 1 }))
        : (field.type === 'checkbox')
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), {
              key: 2,
              checked: __props.model[field.name],
              "onUpdate:checked": ($event: any) => ((__props.model[field.name]) = $event),
              defaultValue: __props.model[field.name],
              name: field.name
            }, null, 40, ["checked", "onUpdate:checked", "defaultValue", "name"]))
          : (field.type === 'file')
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), {
                key: 3,
                file: __props.model[field.name],
                "onUpdate:file": ($event: any) => ((__props.model[field.name]) = $event),
                defaultValue: __props.model[field.name.replace('_file', '')],
                name: field.name,
                onImageValidation: _cache[0] || (_cache[0] = ($event: any) => (emit('imageValidation', $event)))
              }, null, 40, ["file", "onUpdate:file", "defaultValue", "name"]))
            : (['phone', 'input', 'color', 'email'].includes(field.type))
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), {
                  key: 4,
                  modelValue: __props.model[field.name],
                  "onUpdate:modelValue": ($event: any) => ((__props.model[field.name]) = $event),
                  name: field.name,
                  onPhoneValidation: _cache[1] || (_cache[1] = ($event: any) => (emit('phoneValidation', $event)))
                }, null, 40, ["modelValue", "onUpdate:modelValue", "name"]))
              : (_openBlock(), _createBlock(_resolveDynamicComponent(field.component), { key: 5 }))
    ], 12, _hoisted_1))
  }), 256))
}
}

})