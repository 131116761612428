import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sendChat-mainWrapper" }
const _hoisted_2 = { class: "emmaIsTyping" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "suggestions-bar" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "sendChat-wrapper" }

import { useEmmaStore } from '@/entities/emma';
import { storeToRefs } from 'pinia';
import { ref, onMounted, onUnmounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatButton',
  props: /*@__PURE__*/_mergeModels(['onClick'], {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props) {

const emmaStore = useEmmaStore()
const { isRunning } = storeToRefs(emmaStore)
const props = __props
const suggestions = [
  'Done',
  'I’m ready',
  'I feel unwell',
]
const value = _useModel<string>(__props, "modelValue")
const dots = ref('');
const intervalId = ref<any>();

onMounted(() => {
  intervalId.value = setInterval(() => {
    dots.value = dots.value.length < 3 ? dots.value + '.' : '';
  }, 500);
});

onUnmounted(() => {
  clearInterval(intervalId.value);
});

function enterHandler(e : any){
  e.preventDefault()
  props.onClick()
}
function keydownHandler(e : any){
  if((e.ctrlKey || e.altKey) && e.key == "Enter"){
    value.value += '\n';
  }
}
function onSuggestionsClick(text : string){
  value.value = text
  props.onClick()
  value.value = ''
}

return (_ctx: any,_cache: any) => {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(isRunning))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createTextVNode("Emma is typing")),
            _createElementVNode("span", null, _toDisplayString(dots.value), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(suggestions, (msg) => {
        return _createElementVNode("div", {
          onClick: ($event: any) => (onSuggestionsClick(msg))
        }, _toDisplayString(msg), 9, _hoisted_5)
      }), 64))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_a_textarea, {
        onKeydown: keydownHandler,
        autoSize: { minRows: 1, maxRows: 3 },
        placeholder: 'Send a message',
        value: value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        class: "sendChat-input",
        onKeypress: _withKeys(enterHandler, ["enter"])
      }, null, 8, ["value"]),
      _createVNode(_component_a_button, {
        type: "primary",
        class: "sendChat-btn",
        onClick: __props.onClick
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "16",
            height: "16",
            viewBox: "0 0 16 16",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M14.0001 7.59307L7.65048 2.08215C7.60174 2.04013 7.53956 2.0166 7.47401 2.0166H5.98661C5.86224 2.0166 5.8051 2.17122 5.89922 2.2519L11.7849 7.36114H1.94964C1.87569 7.36114 1.81519 7.42164 1.81519 7.49559V8.504C1.81519 8.57795 1.87569 8.63845 1.94964 8.63845H11.7833L5.89754 13.7477C5.80342 13.83 5.86056 13.983 5.98493 13.983H7.52275C7.55468 13.983 7.58661 13.9712 7.61014 13.9494L14.0001 8.40652C14.0582 8.35592 14.1049 8.29343 14.1369 8.22327C14.1688 8.15311 14.1854 8.0769 14.1854 7.99979C14.1854 7.92269 14.1688 7.84648 14.1369 7.77632C14.1049 7.70616 14.0582 7.64367 14.0001 7.59307Z",
              fill: "white"
            })
          ], -1)
        ])),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}
}

})