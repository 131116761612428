import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EmailInput',
  props: {
  placeholder: {
    type: String,
    default: 'E-mail',
  },
  name: {
    type: String,
    default: 'email',
  },
},
  emits: {
  'update:value': (value: string) => typeof value === 'string'
},
  setup(__props, { emit: __emit }) {



const value = ref('');

const emit = __emit;

const emitValue = () => {
  emit('update:value', value.value);
};

watch(()=> value.value, emitValue);

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, { name: __props.name }, {
    default: _withCtx(() => [
      _createVNode(_component_a_input, _mergeProps({
        value: value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        type: "email",
        placeholder: __props.placeholder
      }, _ctx.$attrs), null, 16, ["value", "placeholder"])
    ]),
    _: 1
  }, 8, ["name"]))
}
}

})